import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Nav, Navbar, Container } from 'react-bootstrap'
import '../style/app.scss';

/**
 * Layout to a component, courtesy Gatsby tutorial.
 * 
 * @author Calum O'Hare
 */
const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    const siteTitle = data.site.siteMetadata.title;
    return(
        <>
        <Container>
            <title>{siteTitle} - {pageTitle}</title>
            <Navbar variant="dark" expand="lg">
              <Container>
                <Link className="navbar-brand text-white" to="/">{siteTitle}</Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Link className="nav-link active text-white" to="/">Home</Link>
                    <Link className="nav-link text-white" to="/blog">Blog</Link>
                    <Link className="nav-link text-white" to="/about">About</Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
        </Container>

        <main className="container">
            <Container className="bg-white text-black p-4 mb-5 border border-primary rounded">
              <h1 className="border-bottom">{pageTitle}</h1>
              {children}
            </Container>
        </main>
        </>
    );
}


export default Layout
